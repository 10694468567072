import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { Groups } from "src/auth/types/userToken";
import { isUserOnlyMemberOfGroups } from "src/auth/utils/canTakeAction";
import { useLocalPracticeId } from "src/auth/utils/practice";

const PracticeHome: NextPage = () => {
  const router = useRouter();
  const activePracticeId = useLocalPracticeId();

  useEffect(() => {
    if (activePracticeId !== null) {
      if (isUserOnlyMemberOfGroups([Groups.RCM_OCC_HEALTH])) {
        router.replace(`/${activePracticeId}/settings/occ-health-organizations`);
      } else {
        router.replace(`/${activePracticeId}/teams`);
      }
    }
  }, [activePracticeId, router]);

  return null;
};

export default PracticeHome;
